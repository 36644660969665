/**
 * @name: 网红订单接口
 * @author: itmobai
 * @date: 2023-06-01 15:22
 * @description：网红订单接口
 * @update: 2023-06-01 15:22
 */
import {get, postJ, postW} from "@/request";
import {IInternetOrder, IInternetOrderParam} from "@/apis/order/internet/types";
import {IPageRes} from "@/apis/page";

/**
 * 网红订单分页查询
 * @param params 查询参数
 */
export const orderQueryCyberStarOrderByPageApi = (params: IInternetOrderParam) => get<IPageRes<IInternetOrder[]>>("/golf/order/queryCyberStarOrderByPage", params)
/**
 * 网红订单导出
 * @param params
 */
export const orderCyberStarOrderExportApi = (params: IInternetOrderParam) => get("/golf/order/cyberStarOrderExport", params, 'blob')
/**
 * 网红订单完成
 * @param orderId 订单id
 */
export const orderFinalizeCyberStarOrderApi = (orderId: string) => postW("/golf/order/finalizeCyberStarOrder?orderId=" + orderId, {})
